import { Card, CardContent, CardHeader } from '@mui/material'

import InlineLoader from 'src/components/InlineLoader/InlineLoader'

type ContentCardProps = {
  children: React.ReactNode
  title?: string
  action?: React.ReactNode | React.ReactNode[]
  noPadding?: boolean
  height?: number | string
  isFlex?: boolean
  isColumn?: boolean
  isLoading?: boolean
  isError?: boolean
  data?: boolean
  textAlign?: string
  sx?: object
}

function ContentCard({
  title,
  action,
  children,
  noPadding,
  height,
  isFlex = false,
  isColumn = false,
  isLoading = false,
  isError = false,
  textAlign = 'inherit',
  data = true,
  sx = {},
}: ContentCardProps) {
  const applyNoPadding = noPadding ? { padding: '0px !important' } : {}
  const applyHeight = height ? { height } : {}
  const applyFlex = isFlex ? { display: 'flex !important' } : {}
  const applyColumn = isColumn ? { 'flex-direction': 'column' } : {}
  return (
    <Card sx={{ ...applyHeight }} role="region">
      {!!title && <CardHeader title={title} action={action} sx={{ pb: 0 }} titleTypographyProps={{ variant: 'h2' }} />}
      <CardContent
        sx={{ ...applyNoPadding, ...applyHeight, ...applyFlex, ...applyColumn, textAlign: textAlign, ...sx }}
      >
        {isLoading || isError || !data ? <InlineLoader message="Loading..." /> : children}
      </CardContent>
    </Card>
  )
}

export default ContentCard
