import { InMemoryCache } from '@apollo/client'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

import { RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import theme from 'src/lib/muiTheme'
import Sentry from 'src/lib/sentry'
import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import { AuthProvider, useAuth } from './auth'
import './index.css'
import UiContextProvider from './lib/context/Ui/Provider'

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={FatalErrorPage}>
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthProvider>
            <UiContextProvider>
              <RedwoodApolloProvider useAuth={useAuth} graphQLClientConfig={{ cache: new InMemoryCache() }}>
                <Routes />
              </RedwoodApolloProvider>
            </UiContextProvider>
          </AuthProvider>
        </ThemeProvider>
      </RedwoodProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
