import { createTheme, ThemeOptions } from '@mui/material/styles'

const theme: ThemeOptions = {
  spacing: 8,
  typography: {
    h1: { fontSize: '2.1rem', fontWeight: 100, color: '#3b1a59', ml: 2 },
    h2: { fontSize: '1.5rem' },
    h3: { fontSize: '1.5rem' },
    h4: { fontSize: '1.2rem', color: '#2979ff' },
  },
  components: {
    MuiAppBar: { styleOverrides: { root: { height: 64 } } },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiInputBase-readOnly ~ div > button > svg': {
            display: 'none',
          },
          '.MuiInputBase-readOnly > .MuiChip-root:focus': {
            borderColor: '#51247A !important',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: { variant: 'standard' },
      styleOverrides: {
        root: {
          '& .MuiInputBase-readOnly::before, .MuiInputBase-readOnly::after': {
            borderBottomColor: '#EEE !important',
            borderBottomWidth: '0px !important',
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: { cursor: 'pointer', '&.Mui-disabled': { cursor: 'pointer' } },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledWarning: {
          color: '#000',
          a: { color: '#000', '&:hover': { textDecoration: 'underline' } },
        },
        filledError: { a: { color: '#FFF', '&:hover': { textDecoration: 'underline' } } },
        filledInfo: { backgroundColor: '#1f5abf', a: { color: '#FFF', '&:hover': { textDecoration: 'underline' } } },
        filledSuccess: { a: { color: '#FFF', '&:hover': { textDecoration: 'underline' } } },
        message: { flex: 1 },
      },
    },
  },
  palette: {
    background: { default: '#fafafa' },
    primary: {
      light: '#962A8B',
      main: '#51247A',
      dark: '#3b1a59',
    },
    secondary: {
      light: '#3c76d8',
      main: '#2464d0',
      dark: '#1f56b1',
    },
    warning: {
      light: '#ff9a57',
      main: '#fbb800',
      dark: '#542400',
    },
    success: {
      light: '#00a700',
      main: '#238029',
      dark: '#005000',
    },
    error: {
      light: '#ff0000',
      main: '#951126',
      dark: '#790000',
    },
  },
}

export const layoutHelpers = {
  card: {
    maxWidth: '95%',
    width: 1200,
    position: 'relative' as const,
    margin: '0 auto',
  },
}

export default createTheme(theme)
