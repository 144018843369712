import { Link as InternalLink } from '@redwoodjs/router'

import ExternalLink from '../ExternalLink/ExternalLink'

const isExternal = (href: string) => {
  return href.startsWith('http') || href.startsWith('mailto:') || href.startsWith('tel:') || href.startsWith('#')
}

type Props = {
  children: React.ReactNode
  href: string
  target?: string
  notStyled?: boolean
}

const Link = (props: Props) => {
  if (props.href === '') return <>{props.children}</>

  if (isExternal(props.href)) {
    return (
      <ExternalLink href={props.href} target={props.target || '_blank'} notStyled={props.notStyled}>
        {props.children}
      </ExternalLink>
    )
  } else {
    return (
      <InternalLink to={props.href} style={{ textDecoration: 'none', color: 'inherit' }}>
        {props.children}
      </InternalLink>
    )
  }
}

export default Link
