import { Grid, Link, Typography } from '@mui/material'

const useStyles = {
  position: 'absolute',
  background: 'rgba(255, 255, 255, 0.9)',
  zIndex: 999,
  opacity: 0,
  alignText: 'center',
  width: 0,
  height: 0,
  '&:focus': {
    opacity: 100,
    width: '100%',
    height: '50%',
  },
}

const SkipMenu = () => {
  return (
    <Link href="#content" sx={useStyles} aria-label="Click to skip the main navigation and focus on the main content">
      <Grid
        container
        alignContent={'center'}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{ width: '100%', height: '100%' }}
      >
        <Grid item>
          <Typography color={'primary'}>Skip to content</Typography>
        </Grid>
      </Grid>
    </Link>
  )
}

export default SkipMenu
