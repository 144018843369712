import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'

import UqLogoWhite from 'src/images/uq_logo_white'

const LogoWrapper = styled(Box)({
  backgroundColor: '#51247a',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
})

const CloseIconWrapper = styled(Box)({
  position: 'absolute',
  right: 8,
})

const CloseIconEl = styled(CloseIcon)({
  color: '#FFF',
})

type MenuDrawerLogoBarProps = {
  height: number
  showCloseButton: boolean
  handleMenuClose: () => unknown
}

const MenuDrawerLogoBar = (props: MenuDrawerLogoBarProps) => {
  return (
    <div style={{ height: props.height }}>
      <LogoWrapper height={props.height} sx={{ minHeight: props.height }}>
        <UqLogoWhite width={150} height={32} />
        {props.showCloseButton && (
          <CloseIconWrapper>
            <IconButton onClick={props.handleMenuClose}>
              <CloseIconEl />
            </IconButton>
          </CloseIconWrapper>
        )}
      </LogoWrapper>
    </div>
  )
}

export default MenuDrawerLogoBar
