import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

type InlineLoaderProps = {
  message?: string
}

function InlineLoader({ message }: InlineLoaderProps) {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <CircularProgress size={30} sx={{ mt: 5, mb: 5 }} />
      {message && (
        <>
          <Typography variant="h6" color="primary.dark">
            {message}
          </Typography>
          <br />
          <br />
        </>
      )}
    </Box>
  )
}

export default InlineLoader
