import { BrowserTracing } from '@sentry/browser'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: process.env.SENTRY_DSN_WEB,
  environment: `${process.env.ENVIRONMENT}`,
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 0.2,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  normalizeDepth: 11,
  enabled: process.env.ENVIRONMENT !== 'local',
})

export default Sentry
