import { styled } from '@mui/material/styles'

const AnchorLink = styled('a', {
  shouldForwardProp: (prop) => prop !== 'notStyled',
})<{
  notStyled?: boolean
}>(({ theme, notStyled }) => ({
  color: 'inherit',
  textDecoration: 'none',
  ...(!notStyled && {
    fontWeight: 600,
    color: theme.palette.primary.main,
    '&:hover, &:focus': { textDecoration: 'underline' },
  }),
}))

type ExternalLinkProps = {
  children?: React.ReactNode
  href: string
  target?: string
  notStyled?: boolean
}
const ExternalLink = (props: ExternalLinkProps) => {
  const target = props.target || '_blank'

  return (
    <AnchorLink
      tabIndex={0}
      href={props.href}
      notStyled={props.notStyled}
      target={target}
      rel="noopener noreferrer"
      onClick={(e) => e.stopPropagation()}
    >
      {props.children}
    </AnchorLink>
  )
}

export default ExternalLink
