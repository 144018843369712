import { createContext } from 'react'

import { AlertColor } from '@mui/material'

type UiContextInterface = {
  showSnackbar: (message: string, severity?: AlertColor) => void
  showHelpDrawer: (title: string, message: React.ReactNode, buttonLabel?: string) => void
}

const UiContext = createContext<UiContextInterface>({
  showSnackbar: () => {},
  showHelpDrawer: () => {},
})

export default UiContext
