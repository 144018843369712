import { useState } from 'react'

import { useApolloClient } from '@apollo/client'
import dayjs from 'dayjs'
import {
  ActiveNotificationResult,
  GetActiveNotificationsQuery,
  GetActiveNotificationsQueryVariables,
} from 'types/graphql'

import GET_ACTIVE_NOTIFICATIONS_QUERY from '../graphql/queries/GetActiveNotifications'

type NotificationStore = {
  notifications: ActiveNotificationResult[]
  lastUpdated: Date
}

const useNotificationState = () => {
  const [notificationStore, setNotificationStore] = useState<NotificationStore>(
    JSON.parse(localStorage.getItem('UQRDM_NOTIFICATION_STORE'))
  )
  const [loading, setLoading] = useState(false)
  const client = useApolloClient()

  // Check if we have no local store. Or if local store was updated > 5 minutes before
  if (!notificationStore || dayjs(notificationStore.lastUpdated).isBefore(dayjs().subtract(5, 'minutes'))) {
    if (!loading) {
      setLoading(true)
      client
        .query<GetActiveNotificationsQuery, GetActiveNotificationsQueryVariables>({
          query: GET_ACTIVE_NOTIFICATIONS_QUERY,
        })
        .then((response) => {
          const newStore = {
            notifications: response.data.activeNotifications,
            lastUpdated: new Date(),
          }
          localStorage.setItem('UQRDM_NOTIFICATION_STORE', JSON.stringify(newStore))
          setNotificationStore(newStore)
          setLoading(false)
        })
    }
  }

  return notificationStore?.notifications || []
}

export const clearLocalNotificationState = () => {
  localStorage.removeItem('UQRDM_NOTIFICATION_STORE')
}

export default useNotificationState
