import React from 'react'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Box, Button, Container, Link, Typography } from '@mui/material'
import Popover from '@mui/material/Popover'

import { routes } from '@redwoodjs/router'

import LinkCardSet from 'src/components/LinkCard/LinkCardSet'
import QBIImg from 'src/images/resources/professor_bartlett_qbi_staff.jpg'
import newFeaturesImg from 'src/images/resources/training_female-scientist-looking-through-microscope.jpg'
import inPersonTrainingImg from 'src/images/resources/training_lecturer.jpg'
import learnBasicsImg from 'src/images/resources/training_notebook-learn-underlined.jpg'

const Footer = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'newsletter-popover' : undefined
  const footerData = [
    {
      title: 'Learn the basics of UQRDM',
      image: { src: learnBasicsImg, alt: 'Notebook with learn underlined' },
      body: `Learn how to create a new record, access storage, collaborate or publish your data.`,
      links: [{ url: '/resources/learn-the-basics', text: 'Start learning now', onClick: undefined }],
    },
    // {
    //   title: 'Download helpful resources',
    //   image: { src: helpFulResourcesImg, alt: 'Man at computer' },
    //   body: `Learn more about the benefits
    //         of using UQRDM. Share helpful
    //         resources with your research
    //         partners and collaborators.`,
    //   links: [{ url: '/resources/downloads', text: 'Browse Materials', onClick: undefined }],
    // },
    // {
    //   title: 'View video tutorials',
    //   image: { src: videoTutorialsImg, alt: 'Two students looking at computer' },
    //   body: `View helpful video tutorials to
    //         learn how to utilize all benefits
    //         of UQRDM.`,
    //   links: [{ url: '/resources/videos', text: 'View videos', onClick: undefined }],
    // },
    {
      title: 'Online and in-person training',
      image: { src: inPersonTrainingImg, alt: 'Woman giving lecture' },
      body: `Get in-person, introductory and
            advanced level training for researchers and
            HDR students.`,
      links: [{ url: '/resources/upcoming-training', text: 'Get Training', onClick: undefined }],
    },
    {
      title: 'Request new features',
      image: {
        src: newFeaturesImg,
        alt: 'Woman looking through microscope',
      },
      body: `Is there a feature you would like to
            see within UQRDM? Do you have
            feedback on how UQRDM works?
            Help us improve UQRDM.`,
      links: [{ url: routes.feedback(), text: 'Contact us', onClick: undefined }],
    },
    {
      title: 'UQRDM Newsletters',
      image: {
        src: QBIImg,
        alt: 'Keep up to date with UQRDM',
      },
      body: `Stay up to date with the latest features and improvements of UQRDM with our regular newsletters – sent quarterly to users who have logged in to UQ Research Data Manager within the last 365 days.`,
      links: [
        {
          url: 'https://resources.rdm.uq.edu.au/newsletter/UQRDM_Newsletter_Mar2023.pdf',
          text: 'Latest: March 2023',
          onClick: undefined,
        },
        { url: '', text: 'Newsletter archive', onClick: handleClick },
        {
          url: 'https://cdn.forms-content.sg-form.com/19eb32d3-e61a-11ec-8e57-7ad6d0b8f87c',
          text: 'Subscribe to our newsletter',
        },
      ],
    },
  ]
  const newsletterData = [
    { url: 'https://resources.rdm.uq.edu.au/newsletter/UQRDM_Newsletter_Dec2022.pdf', text: 'December 2022' },
    { url: 'https://resources.rdm.uq.edu.au/newsletter/UQRDM_Newsletter_May2022.pdf', text: 'May 2022' },
    { url: 'https://resources.rdm.uq.edu.au/newsletter/UQRDM_Newsletter_Dec2021.pdf', text: 'December 2021' },
    { url: 'https://resources.rdm.uq.edu.au/newsletter/UQRDM_Newsletter_Nov2021.pdf', text: 'November 2021' },
    { url: 'https://resources.rdm.uq.edu.au/newsletter/UQRDM_Newsletter_Aug2021.pdf', text: 'August 2021' },
    { url: 'https://resources.rdm.uq.edu.au/newsletter/UQRDM_Newsletter_May2021.pdf', text: 'May 2021' },
  ]

  return (
    <>
      <Box sx={{ p: 2, mt: 3 }}>
        <Container maxWidth="lg">
          <LinkCardSet data={footerData} />
        </Container>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <Box sx={{ p: 1, width: 300 }}>
          <Typography gutterBottom variant="h6" component="h4" sx={{ fontSize: 16 }}>
            Select an archived newsletter:
          </Typography>
          {newsletterData.map((link) => (
            <Link href={link.url} underline="none" target="_blank" rel="noreferrer" key={link.text}>
              <Button size="small" color="primary" endIcon={<NavigateNextIcon />} fullWidth>
                {link.text}
              </Button>
            </Link>
          ))}
        </Box>
      </Popover>
    </>
  )
}

export default Footer
