import { Link } from '@mui/material'

import { useAuth } from 'src/auth'
import ContentCard from 'src/components/ContentCard/ContentCard'

/**
 * Graduate school dashboard greeting text
 */
const GraduateSchoolDashboardGreetings = () => {
  const { currentUser } = useAuth()

  return (
    <ContentCard title={`Welcome ${currentUser.displayName}`}>
      Welcome to the graduate school dashboard. For any questions about the graduate school dashboard contact{' '}
      <Link href="mailto:rdm-team@uq.edu.au">rdm-team@uq.edu.au</Link>
    </ContentCard>
  )
}

export default GraduateSchoolDashboardGreetings
