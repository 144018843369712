import Grid from '@mui/material/Unstable_Grid2'

import LinkCard, { LinkCardProps } from './LinkCard'

interface LinkCardSetProps {
  data: LinkCardProps[]
  imgHeight?: number
}

function LinkCardSet(props: LinkCardSetProps) {
  const { data, imgHeight = undefined } = props
  return (
    <Grid container spacing={4}>
      {data.map((card, index) => {
        const { links, image, title, body } = card
        return (
          <Grid xs={12} sm={6} md={3} lg={3} key={index}>
            <LinkCard links={links} image={image} title={title} body={body} imgHeight={imgHeight} />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default LinkCardSet
