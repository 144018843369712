import { Alert, AlertColor, AlertTitle, Stack } from '@mui/material'
import Markdown from 'markdown-to-jsx'


import useNotificationState from 'src/lib/hooks/useNotificationState'

const Notifications = () => {
  const [dismissedNotifications, setDismissedNotifications] = React.useState<string[]>(
    JSON.parse(localStorage.getItem('dismissedNotifications')) || []
  )
  const activeNotifications = useNotificationState()

  const onDismissNotification = (id: string) => () => {
    localStorage.setItem('dismissedNotifications', JSON.stringify([...dismissedNotifications, id]))
    setDismissedNotifications([...dismissedNotifications, id])
  }

  const notifications = [...activeNotifications]
  notifications.sort((a, b) => {
    return a.priority < b.priority ? 1 : -1
  })

  return (
    <Stack spacing={1} sx={{ mb: 1 }}>
      {notifications
        .filter((notification) => !dismissedNotifications.includes(`${notification.id}-${notification.updatedAt}`))
        .map((notification) => {
          const severity = notification.type.toLowerCase() as AlertColor
          const role = severity === 'error' || severity === 'warning' ? 'alert' : 'document'
          return (
            <Alert
              variant="filled"
              role={role}
              key={notification.id}
              severity={severity}
              onClose={onDismissNotification(`${notification.id}-${notification.updatedAt}`)}
            >
              {notification.title && <AlertTitle>{notification.title}</AlertTitle>}
              <Markdown>{notification.message}</Markdown>
            </Alert>
          )
        })}
    </Stack>
  )
}

export default Notifications
