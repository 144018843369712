import MenuIcon from '@mui/icons-material/Menu'
import { AppBar as MuiAppBar, IconButton, Toolbar, Typography } from '@mui/material'

import { useLocation } from '@redwoodjs/router'

type AppBarProps = {
  onToggleDrawer: () => unknown
}

const styles = { menuIcon: { display: { sm: 'block', lg: 'none', top: 2 } } }

const AppBar = ({ onToggleDrawer }: AppBarProps) => {
  const { pathname } = useLocation()
  const isHome = pathname === '/'
  return (
    <MuiAppBar
      position="static"
      style={{
        background: 'linear-gradient(100deg, rgba(81,36,122,1) 70%, rgba(150,42,139,1) 100%)',
        boxShadow: 'none',
      }}
    >
      <Toolbar>
        <IconButton color="inherit" aria-label="open drawer" onClick={onToggleDrawer} sx={styles.menuIcon}>
          <MenuIcon />
        </IconButton>
        <Typography
          component={isHome ? 'h1' : 'h6'}
          variant="h6"
          color="inherit"
          noWrap
          sx={{ ml: 2, fontWeight: '100', textShadow: '1px 1px 3px #333333' }}
        >
          UQ Research Data Manager
        </Typography>
      </Toolbar>
    </MuiAppBar>
  )
}
export default AppBar
