import { useCallback, useMemo, useState } from 'react'

import { Alert, AlertColor, Box, Divider, Drawer, Snackbar, Typography } from '@mui/material'

import Button from 'src/components/Button/Button'

import UiContext from './Context'

type SnackBarStatus = {
  open: boolean
  message: string
  severity?: AlertColor
}
const defaultSnackbarStatus = {
  open: false,
  message: '',
  severity: undefined,
}

type HelpDrawerStatus = {
  open: boolean
  title: string
  text: React.ReactNode
  buttonLabel: string
}
const defaultHelpDrawerStatus = {
  open: false,
  title: '',
  text: '',
  buttonLabel: '',
}

type UiContextProviderProps = {
  children: React.ReactNode
}

const UiContextProvider = ({ children }: UiContextProviderProps) => {
  const [snackbar, setSnackbar] = useState<SnackBarStatus>(defaultSnackbarStatus)
  const [helpDrawer, setHelpDrawer] = useState<HelpDrawerStatus>(defaultHelpDrawerStatus)

  function onOpenSnackbar(message: string, severity?: AlertColor): void {
    setSnackbar({ message, severity, open: true })
  }

  const onCloseSnackbar = useCallback(() => {
    setSnackbar(defaultSnackbarStatus)
  }, [setSnackbar])

  function onOpenHelpDrawer(title: string, text: React.ReactNode, buttonLabel = 'Got it!'): void {
    setHelpDrawer({ title, text, buttonLabel, open: true })
  }

  const onCloseHelpDrawer = useCallback(() => {
    setHelpDrawer(defaultHelpDrawerStatus)
  }, [setHelpDrawer])

  const value = useMemo(() => ({ showSnackbar: onOpenSnackbar, showHelpDrawer: onOpenHelpDrawer }), [])

  return (
    <UiContext.Provider value={value}>
      {children}
      <Snackbar
        aria-live='assertive'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbar.open}
        autoHideDuration={10000}
        onClose={onCloseSnackbar}
        title={snackbar.severity ? snackbar.message : undefined}
      >
        {snackbar.severity && (
          <Alert severity={snackbar.severity} variant="filled">
            {snackbar.message}
          </Alert>
        )}
      </Snackbar>
      <Drawer open={helpDrawer.open} anchor="right" onClose={onCloseHelpDrawer} sx={{ zIndex: 9999 }} role="dialog">
        <Box sx={{ width: '400px', padding: 2 }}>
          <Typography variant="h3" component="h3" color="primary" gutterBottom>
            {helpDrawer.title}
          </Typography>
          <Divider light sx={{ mt: 1, mb: 1 }} />
          <Typography variant="subtitle1">{helpDrawer.text}</Typography>
          <br />
          <Button color="secondary" onClick={onCloseHelpDrawer} sx={{ width: '100%' }}>
            {helpDrawer.buttonLabel}
          </Button>
        </Box>
      </Drawer>
    </UiContext.Provider>
  )
}
export default UiContextProvider
