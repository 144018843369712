import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledPageWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  flexGrow: 1,
  padding: 16,
  display: 'flex',
  justifyContent: 'center',
  minWidth: 200,
}))

type PageContentProps = {
  maxWidth: number | string
}
const PageContent = styled(Box)<PageContentProps>(({ maxWidth }) => ({
  flexGrow: 1,
  width: '100%',
  maxWidth,
}))

type PageWrapperProps = {
  maxWidth?: number | string
  children?: React.ReactNode
}

const PageWrapper = (props: PageWrapperProps) => {
  const maxWidth = props.maxWidth || 1200

  return (
    <StyledPageWrapper id="pagewrapper">
      <PageContent maxWidth={maxWidth}>{props.children}</PageContent>
    </StyledPageWrapper>
  )
}

export default PageWrapper
