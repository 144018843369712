import { useState } from 'react'

import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Unstable_Grid2'

import { useAuth } from 'src/auth'
import AppBar from 'src/components/AppBar/AppBar'
import MenuDrawer from 'src/components/MenuDrawer/MenuDrawer'
import Notifications from 'src/components/Notifications/Notifications'
import Footer from 'src/pages/ResourceHub/components/Footer'

import menuItems from '../PrimaryLayout/menuItems'

const StyledPageWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
}))

type PrimaryLayoutProps = {
  children?: React.ReactNode
}

const PrimaryLayout = ({ children }: PrimaryLayoutProps) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const { hasRole } = useAuth()

  const toggleDrawer = () => setDrawerOpen(!drawerOpen)

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <MenuDrawer open={drawerOpen} handleMenuClose={toggleDrawer} menuItems={menuItems(hasRole)} />
      <Grid container direction="column" sx={{ width: '100%', flexWrap: 'nowrap' }}>
        <AppBar onToggleDrawer={toggleDrawer} />
        <StyledPageWrapper>
          <Box sx={{ flexGrow: 1 }}>
            <Notifications />
            {children}
            <Footer />
          </Box>
        </StyledPageWrapper>
      </Grid>
    </Box>
  )
}

export default PrimaryLayout
