const GET_ACTIVE_NOTIFICATIONS_QUERY = gql`
  query GetActiveNotificationsQuery {
    activeNotifications {
      id
      type
      title
      priority
      message
      updatedAt
    }
  }
`

export default GET_ACTIVE_NOTIFICATIONS_QUERY
