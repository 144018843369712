import { List, ListItemButton, ListItemText } from '@mui/material'

import { useLocation } from '@redwoodjs/router'

import MenuItem from 'src/types/MenuItem'

type MenuItemsProps = {
  menuItems: MenuItem[]
}

const MenuItems = ({ menuItems }: MenuItemsProps) => {
  const location = useLocation()

  return (
    <List disablePadding role="menu">
      {menuItems.map((item) => (
        <ListItemButton
          role="menuitem"
          target={item.openBlank ? '_blank' : undefined}
          href={item.linkTo}
          key={item.primaryText}
          selected={location.pathname === item.linkTo}
        >
          <ListItemText primary={item.primaryText} secondary={item.secondaryText} />
          {item.icon}
        </ListItemButton>
      ))}
    </List>
  )
}

export default MenuItems
