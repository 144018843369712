import { useState } from 'react'

import Grid from '@mui/material/Unstable_Grid2'
import { Box } from '@mui/system'

import { useAuth } from 'src/auth'
import AppBar from 'src/components/AppBar/AppBar'
import MenuDrawer from 'src/components/MenuDrawer/MenuDrawer'
import Notifications from 'src/components/Notifications/Notifications'
import PageWrapper from 'src/components/Page/PageWrapper'

import menuItems from './menuItems'

type Props = {
  children?: React.ReactNode
}

const GraduateSchoolLayout = ({ children }: Props) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const { hasRole } = useAuth()

  const toggleDrawer = () => setDrawerOpen(!drawerOpen)

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <MenuDrawer open={drawerOpen} handleMenuClose={toggleDrawer} menuItems={menuItems(hasRole)} />
      <Grid container direction="column" sx={{ width: '100%', flexWrap: 'nowrap' }} alignItems="stretch">
        <AppBar onToggleDrawer={toggleDrawer} />
        <main id="content" style={{ display: 'flex', flexGrow: 1 }}>
          <PageWrapper>
            <Notifications />
            {children}
          </PageWrapper>
        </main>
      </Grid>
    </Box>
  )
}

export default GraduateSchoolLayout
